/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import { When } from "react-if";
import styled from "styled-components";
import {
  Box,
  Button,
  FlexContainer,
  Input,
  Label,
  Select,
  Text,
  theme,
} from "@nordcloud/gnui";
import { PlanNotificationTriggerType } from "~/generated/graphql";
import { FormGroup } from "~/components";
import { NotificationGroupSidebar } from "../NotificationGroupSidebar";
import { PlanField } from "../PlanCreateWizard/constants";
import { usePlanWizard } from "../PlanCreateWizard/PlanProvider";
import {
  FormField,
  NotificationGroupItemGeneral,
  Option,
} from "../PlanCreateWizard/types";
import { optionsCondition } from "./constants";
import { InAdvance } from "./InAdvance";

type Props = {
  remove: () => void;
  index: number;
};

export function NotificationItemGeneral({ remove, index }: Props) {
  const { watch, setValue, control, formState, register } = useFormContext();
  const { setPlanData } = usePlanWizard();

  const error = formState.errors[FormField.NOTIFICATION_GROUPS_GENERAL] as
    | FieldValues[]
    | undefined;

  const id = watch(
    `${[FormField.NOTIFICATION_GROUPS_GENERAL]}.${index}.${[FormField.ID]}`
  );
  const triggerEvent = watch(
    `${[FormField.NOTIFICATION_GROUPS_GENERAL]}.${index}.${[
      FormField.TRIGGER_EVENT,
    ]}`
  );
  const notificationGroupsIds = watch(
    `${[FormField.NOTIFICATION_GROUPS_GENERAL]}.${index}.${[
      FormField.NOTIFICATION_GROUPS_IDS,
    ]}`
  );
  const notificationGroups = watch(FormField.NOTIFICATION_GROUPS_GENERAL);

  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    notificationGroupsIds ?? []
  );

  const handleChange = (name: FormField[], value?: string | string[]) => {
    const field = `${[FormField.NOTIFICATION_GROUPS_GENERAL]}.${index}.${name}`;
    setValue(field, value);
  };

  const handleRemove = (itemId: string) => {
    const filteredNotificationGroups = notificationGroups?.filter(
      (i: NotificationGroupItemGeneral) => {
        return i?.id !== itemId;
      }
    );

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.GENERAL_NOTIFICATIONS]: {
        notificationGroupsGeneral: filteredNotificationGroups,
      },
    }));
    remove();
  };

  const notificationCount = index + 1;
  return (
    <Box mb={theme.spacing.spacing02} boxStyle="lightGrey">
      <Headline>
        <Text tag="div" weight="medium">
          Notification #{notificationCount}
        </Text>
        <Button
          severity="low"
          ml="auto"
          icon="trash"
          onClick={() => handleRemove(id)}
        />
      </Headline>
      <div css={{ marginTop: theme.spacing.spacing04 }}>
        <div css={{ display: "none" }}>
          <Input
            hidden
            defaultValue={id}
            {...register(
              `${[FormField.NOTIFICATION_GROUPS_GENERAL]}.${index}.${[
                FormField.ID,
              ]}`
            )}
          />
        </div>
        <FormGroup error={error?.[index]?.triggerEvent}>
          <Label name="Condition" />
          <Controller
            control={control}
            name={`${[FormField.NOTIFICATION_GROUPS_GENERAL]}.${index}.${[
              FormField.TRIGGER_EVENT,
            ]}`}
            render={() => {
              return (
                <Select
                  placeholder="Select Option"
                  options={optionsCondition}
                  onChange={(option: Option | null) =>
                    handleChange([FormField.TRIGGER_EVENT], option?.value)
                  }
                  value={optionsCondition.find((i) => i.value === triggerEvent)}
                  isSearchable={false}
                />
              );
            }}
          />
        </FormGroup>
        <FormGroup error={error?.[index]?.notificationGroupIds}>
          <NotificationGroupSidebar
            onSubmit={(ids) =>
              handleChange([FormField.NOTIFICATION_GROUPS_IDS], ids)
            }
            onClear={() => {
              return handleChange(
                [FormField.NOTIFICATION_GROUPS_IDS],
                undefined
              );
            }}
            {...{ selectedOptions, setSelectedOptions }}
          />
        </FormGroup>
        <When
          condition={
            triggerEvent === PlanNotificationTriggerType.EventsIncoming
          }
        >
          <InAdvance {...{ handleChange, index }} />
        </When>
      </div>
    </Box>
  );
}

const Headline = styled(FlexContainer)`
  border-bottom: ${theme.borders.grey};
  margin: -${theme.spacing.spacing03} -${theme.spacing.spacing03} 0;
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing03}
    ${theme.spacing.spacing02};
`;
