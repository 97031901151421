/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import { When } from "react-if";
import styled from "styled-components";
import {
  Box,
  Button,
  Dropdown,
  FlexContainer,
  Input,
  Label,
  Select,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import {
  PlanActionNotificationGroupScope,
  ActionScope,
  PlanActionNotificationTriggerType,
} from "~/generated/graphql";
import { FormGroup } from "~/components";
import { NotificationGroupSidebar } from "../NotificationGroupSidebar";
import { PlanField } from "../PlanCreateWizard/constants";
import { usePlanWizard } from "../PlanCreateWizard/PlanProvider";
import { units } from "../PlanCreateWizard/PlanSettingsForms/components/ActionSettingsForm/options";
import { convertStringToTimeUnit } from "../PlanCreateWizard/PlanSettingsForms/components/utils";
import { useStepState } from "../PlanCreateWizard/PlanSettingsForms/StepProvider";
import {
  FormField,
  NotificationGroupItem,
  Option,
  TimeUnits,
} from "../PlanCreateWizard/types";
import {
  optionsScope,
  optionsTriggerResource,
  optionsTriggerAction,
} from "./constants";

type Props = {
  remove: () => void;
  index: number;
};

export function NotificationItem({ remove, index }: Props) {
  const {
    watch,
    setValue,
    control,
    formState,
    register,
    setError,
    clearErrors,
  } = useFormContext();

  const error = formState.errors[`notificationGroups`] as
    | FieldValues[]
    | undefined;

  const id = watch(
    `${[FormField.NOTIFICATION_GROUPS]}.${index}.${[FormField.ID]}`
  );
  const scope = watch(
    `${[FormField.NOTIFICATION_GROUPS]}.${index}.${[FormField.SCOPE]}`
  );

  const triggerEvent = watch(
    `${[FormField.NOTIFICATION_GROUPS]}.${index}.${[FormField.TRIGGER_EVENT]}`
  );

  const notificationGroupsIds = watch(
    `${[FormField.NOTIFICATION_GROUPS]}.${index}.${[
      FormField.NOTIFICATION_GROUPS_IDS,
    ]}`
  );
  const notificationGroups = watch(FormField.NOTIFICATION_GROUPS);

  const { stepState, updateStepState } = useStepState();
  const actionScope = stepState.selectedAction?.scope;

  const scopeOptions =
    actionScope === ActionScope.Plan
      ? optionsScope.filter(
          (i) => i.value === PlanActionNotificationGroupScope.Action
        )
      : optionsScope;

  const triggerTypeOptions =
    scope === PlanActionNotificationGroupScope.Action
      ? optionsTriggerAction
      : optionsTriggerResource;

  const { setPlanData } = usePlanWizard();
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    notificationGroupsIds ?? []
  );

  const handleChange = (
    name: FormField[],
    value?: string | string[],
    idToRemove?: string
  ) => {
    const stepActions = stepState?.actions ?? [];
    const actionIndex =
      stepActions?.findIndex(
        (action) => action.listId === stepState.selectedAction?.listId
      ) ?? -1;

    const field = `${[FormField.NOTIFICATION_GROUPS]}.${index}.${[name]}`;
    setValue(field, value);

    const filteredNotificationGroups = notificationGroups?.filter(
      (i: NotificationGroupItem) => {
        return i?.id !== idToRemove;
      }
    );
    const updatedAction = {
      ...stepActions?.[actionIndex],
      [FormField.NOTIFICATION_GROUPS]: [...filteredNotificationGroups],
    };
    const actions = [
      ...stepActions?.slice(0, actionIndex),
      updatedAction,
      ...stepActions?.slice(actionIndex + 1),
    ];
    updateStepState({
      actions: actions,
      selectedAction: updatedAction,
    });

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planActions: [...(actions ?? [])],
      },
    }));
  };

  const handleRemove = (itemId: string) => {
    handleChange([FormField.SCOPE], undefined, itemId);
    remove();
  };

  function handleScopeChangeWithDefaultValue(value: string) {
    handleChange([FormField.SCOPE], value);
    return (val: string) => {
      const defaultValue =
        val === PlanActionNotificationGroupScope.Action
          ? optionsTriggerAction
          : optionsTriggerResource;
      return handleChange([FormField.TRIGGER_EVENT], defaultValue[0].value);
    };
  }

  const notificationCount = index + 1;
  const inAdvanceError = formState.errors[FormField.IN_ADVANCE];

  return (
    <Box mb={theme.spacing.spacing02} boxStyle="lightGrey">
      <Headline>
        <Text tag="div" weight="medium">
          Notification #{notificationCount}
        </Text>
        <Button
          severity="low"
          ml="auto"
          icon="trash"
          onClick={() => handleRemove(id)}
        />
      </Headline>
      <div css={{ marginTop: theme.spacing.spacing04 }}>
        <div css={{ display: "none" }}>
          <Input
            hidden
            defaultValue={id}
            {...register(
              `${[FormField.NOTIFICATION_GROUPS]}.${index}.${[FormField.ID]}`
            )}
          />
        </div>

        <FormGroup error={error?.[index]?.scope}>
          <Label name="When" />
          <Controller
            control={control}
            name={`${[FormField.NOTIFICATION_GROUPS]}.${index}.${[
              FormField.SCOPE,
            ]}`}
            render={() => {
              return (
                <Select
                  placeholder="Select scope"
                  options={scopeOptions}
                  onChange={(option: Option | null) =>
                    handleScopeChangeWithDefaultValue(option?.value ?? "")(
                      option?.value ?? ""
                    )
                  }
                  value={scopeOptions.find((i) => i.value === scope)}
                  isSearchable={false}
                />
              );
            }}
          />
        </FormGroup>
        <Spacer height={theme.spacing.spacing02} />
        <FormGroup error={error?.[index]?.triggerEvent}>
          <Controller
            control={control}
            name={`${[FormField.NOTIFICATION_GROUPS]}.${index}.${[
              FormField.TRIGGER_EVENT,
            ]}`}
            render={() => {
              return (
                <Select
                  placeholder="Select event"
                  options={triggerTypeOptions}
                  onChange={(option: Option | null) =>
                    handleChange([FormField.TRIGGER_EVENT], option?.value)
                  }
                  value={triggerTypeOptions.find(
                    (i) => i.value === triggerEvent
                  )}
                  isSearchable={false}
                />
              );
            }}
          />
        </FormGroup>
        <Spacer height={theme.spacing.spacing04} />
        <When
          condition={[
            PlanActionNotificationTriggerType.EventActionsIncoming,
            PlanActionNotificationTriggerType.EventActionsNotApproved,
          ].includes(triggerEvent)}
        >
          <Label
            css={{ marginBottom: "0" }}
            htmlFor="inAdvance"
            name="In Advance"
          />
          <Text mb={theme.spacing.spacing02} size="sm" tag="div">
            Provide how much notice you want before action starts
          </Text>
          <FormGroup
            error={error?.[index]?.inAdvance}
            customCss={{ position: "relative" }}
          >
            <FlexContainer
              mb={theme.spacing.spacing04}
              gap={theme.spacing.spacing03}
              alignItems="flex-start"
            >
              <div css={{ width: "10rem" }}>
                <Controller
                  control={control}
                  name={`${[FormField.NOTIFICATION_GROUPS]}.${index}.${[
                    FormField.IN_ADVANCE,
                  ]}`}
                  render={({ field }) => {
                    return (
                      <FormGroup
                        error={inAdvanceError}
                        customCss={{ position: "relative" }}
                      >
                        <Input
                          {...field}
                          type="number"
                          value={field.value ?? 0}
                          {...register(
                            `${[FormField.NOTIFICATION_GROUPS]}.${index}.${[
                              FormField.IN_ADVANCE,
                            ]}`
                          )}
                          onChange={(e) => {
                            if (Number(e.target.value) <= 0) {
                              setError(FormField.IN_ADVANCE, {
                                type: "min",
                                message: "In advance must be greater than 0",
                              });

                              return;
                            }

                            if (formState.errors[FormField.IN_ADVANCE]) {
                              clearErrors(FormField.IN_ADVANCE);
                            }

                            handleChange(
                              [FormField.IN_ADVANCE],
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    );
                  }}
                />
              </div>

              <FormGroup>
                <div css={{ width: "10rem" }}>
                  <Controller
                    control={control}
                    name={`${[FormField.NOTIFICATION_GROUPS]}.${index}.${[
                      FormField.IN_ADVANCE_UNIT,
                    ]}`}
                    render={({ field: { value } }) => {
                      const handleUnitChange = (val: string) => {
                        const timeUnit = convertStringToTimeUnit(val);

                        handleChange([FormField.IN_ADVANCE_UNIT], timeUnit);
                      };

                      return (
                        <Dropdown
                          name="Select Unit"
                          options={units}
                          onChange={handleUnitChange}
                          value={value ?? TimeUnits.minutes}
                          minNumOfOptionsToShowSearchBox={10}
                        />
                      );
                    }}
                  />
                </div>
              </FormGroup>
            </FlexContainer>
          </FormGroup>
        </When>

        <FormGroup error={error?.[index]?.notificationGroupIds}>
          <NotificationGroupSidebar
            onSubmit={(ids) =>
              handleChange([FormField.NOTIFICATION_GROUPS_IDS], ids)
            }
            onClear={() => {
              return handleChange(
                [FormField.NOTIFICATION_GROUPS_IDS],
                undefined
              );
            }}
            {...{ selectedOptions, setSelectedOptions }}
          />
        </FormGroup>
      </div>
    </Box>
  );
}

const Headline = styled(FlexContainer)`
  border-bottom: ${theme.borders.grey};
  margin: -${theme.spacing.spacing03} -${theme.spacing.spacing03} 0;
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing03}
    ${theme.spacing.spacing02};
`;
