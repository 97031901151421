/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormProvider,
  useFieldArray,
  useForm,
  SubmitHandler,
} from "react-hook-form";
import { v4 } from "uuid";
import { z } from "zod";
import { Button } from "@nordcloud/gnui";
import { PlanActionNotificationTriggerType } from "~/generated/graphql";
import { stringRequired } from "~/components/Forms";
import { NotificationItem } from "~/views/plans/PlanCreate/components/NotificationItem";
import { FormData } from "~/views/plans/PlanCreate/components/PlanCreateWizard/formConfig";
import {
  FormField,
  TimeUnits,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { useStepState } from "../../StepProvider";

export const NotificationGroupItemSchema = z.object({
  [FormField.ID]: z.string(),
  [FormField.SCOPE]: stringRequired("Scope"),
  [FormField.TRIGGER_EVENT]: stringRequired("Trigger Event"),
  [FormField.NOTIFICATION_GROUPS_IDS]: z.string().array(),
});

export const NotificationGroupSchema = z.object({
  [FormField.NOTIFICATION_GROUPS]: NotificationGroupItemSchema.array(),
});

type Props = {
  nextStep?: () => void;
  setTab: (key: number) => void;
};

export function NotificationForm({ nextStep, setTab }: Props) {
  const { stepState } = useStepState();

  const formMethods = useForm<FormData>({
    resolver: zodResolver(NotificationGroupSchema),
  });

  const { control, handleSubmit, formState } = formMethods;

  const submit: SubmitHandler<FormData> = () => {
    nextStep?.();
  };

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "notificationGroups",
  });

  useEffect(() => {
    const defaultValues = stepState.selectedAction?.notificationGroups?.map(
      (i) => {
        const ids = i.notificationGroup?.id
          ? [i?.notificationGroup?.id]
          : i.notificationGroupIds;
        const triggerEvent = i.triggerEvent;

        const item = {
          [FormField.ID]: i.id,
          [FormField.NOTIFICATION_GROUPS_IDS]: ids,
          [FormField.SCOPE]: i.scope,
          [FormField.TRIGGER_EVENT]: triggerEvent,
          [FormField.IN_ADVANCE]:
            i[FormField.IN_ADVANCE] ?? IN_ADVANCE_DEFAULT_MINUTES,
          [FormField.IN_ADVANCE_UNIT]:
            i[FormField.IN_ADVANCE_UNIT] ?? TimeUnits.minutes,
        };

        if (
          triggerEvent ===
            PlanActionNotificationTriggerType.EventActionsNotApproved ||
          triggerEvent ===
            PlanActionNotificationTriggerType.EventActionsIncoming
        ) {
          return {
            ...item,
            [FormField.IN_ADVANCE]: IN_ADVANCE_NOTICE_MINUTES,
          };
        }

        return item;
      }
    );

    replace(defaultValues);
  }, [replace, stepState.selectedAction?.notificationGroups]);

  if (formState.errors.notificationGroups) {
    setTab(2);
  }

  return (
    <FormProvider {...formMethods}>
      <form id="planSettingsForm" onSubmit={handleSubmit(submit)}>
        {fields.map((field, index) => {
          return (
            <NotificationItem
              key={field.id}
              index={index}
              remove={() => remove(index)}
            />
          );
        })}
        <Button
          severity="low"
          icon="plus"
          type="button"
          onClick={() =>
            append({
              [FormField.ID]: v4(),
              [FormField.SCOPE]: undefined,
              [FormField.TRIGGER_EVENT]: undefined,
              [FormField.NOTIFICATION_GROUPS_IDS]: undefined,
            })
          }
        >
          Add Notification
        </Button>
      </form>
    </FormProvider>
  );
}

const IN_ADVANCE_DEFAULT_MINUTES = 0;
const IN_ADVANCE_NOTICE_MINUTES = 1;
