/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export type State = {
  currentStep: number;
  subStep: number;
  planId: string;
};

import {
  Action,
  ActionScope,
  ExecutionPolicy,
  SystemAction,
  PlanActionType,
  KeyValue,
  SkipWindowType,
  PlanActionNotificationGroupScope,
  PlanActionNotificationTriggerType,
  Maybe,
  PlanNotificationTriggerType,
} from "~/generated/graphql";

type Actions = Partial<Action> | Partial<SystemAction>;

export enum FormField {
  LIST_ID = "listId",
  ID = "id",
  NAME = "name",
  DESCRIPTION = "description",
  METADATA = "metadata",
  SCHEDULE_TIMEZONE = "scheduleTimezone",
  SCHEDULE_OFFSET = "scheduleOffset",
  SCHEDULE_OFFSET_UNIT = "scheduleOffsetUnit",
  SCHEDULE_CRON = "scheduleCron",
  PLAN_ACTIONS = "planActions",
  RESOURCE_GROUP_IDS = "resourceGroupIds",
  SCOPE = "scope",
  UNIT = "unit",
  EXECUTION_POLICY = "executionPolicy",
  RUN_IN_SEQUENCE = "runInSequence",
  SKIP_WINDOW = "skipWindow",
  ACTION_TYPE = "actionType",
  WINDOW_DURATION = "windowDuration",
  INPUT_PARAMETERS = "inputParameters",
  VERSION = "version",
  NOTIFICATION_GROUPS = "notificationGroups",
  NOTIFICATION_GROUP = "notificationGroup",
  NOTIFICATION_GROUPS_SCOPE = "notificationGroupsScope",
  TRIGGER_EVENT = "triggerEvent",
  NOTIFICATION_GROUPS_IDS = "notificationGroupIds",
  IN_ADVANCE = "inAdvance",
  IN_ADVANCE_UNIT = "inAdvanceUnit",
  NOTIFICATION_GROUPS_GENERAL = "notificationGroupsGeneral",
}

export type SelectedAction = Actions & {
  [FormField.NAME]?: string;
  [FormField.VERSION]?: string;
  [FormField.LIST_ID]?: string;
  [FormField.SCOPE]?: ActionScope;
  [FormField.RESOURCE_GROUP_IDS]?: string[];
  [FormField.WINDOW_DURATION]?: string;
  [FormField.UNIT]?: TimeUnits;
  [FormField.EXECUTION_POLICY]?: ExecutionPolicy;
  [FormField.RUN_IN_SEQUENCE]?: boolean;
  [FormField.SKIP_WINDOW]?: SkipWindowType;
  [FormField.ACTION_TYPE]?: PlanActionType;
  [FormField.INPUT_PARAMETERS]?: KeyValue[];
  [FormField.NOTIFICATION_GROUPS]?: NotificationGroupItem[];
  selectedTab?: number;
};

export type NotificationGroupItem = {
  [FormField.ID]?: string;
  [FormField.SCOPE]?: PlanActionNotificationGroupScope;
  [FormField.TRIGGER_EVENT]?: PlanActionNotificationTriggerType;
  [FormField.NOTIFICATION_GROUPS_IDS]?: string[];
  [FormField.NOTIFICATION_GROUP]?: {
    [FormField.ID]?: string;
    [FormField.NAME]?: string;
  };
  [FormField.IN_ADVANCE]?: Maybe<number>;
  [FormField.IN_ADVANCE_UNIT]?: TimeUnits;
};
export type NotificationGroupItemGeneral = {
  [FormField.ID]?: string;
  [FormField.TRIGGER_EVENT]?: PlanNotificationTriggerType;
  [FormField.NOTIFICATION_GROUPS_IDS]?: string[];
  [FormField.IN_ADVANCE]?: string;
  [FormField.UNIT]?: TimeUnits;
};

export type StepState = {
  actions?: SelectedAction[];
  selectedAction?: SelectedAction;
};

export enum TimeUnits {
  hours = "hours",
  minutes = "minutes",
  days = "days",
  weeks = "weeks",
}

export type Option = {
  label: string;
  value: string;
};
